import React, { useReducer, createContext } from 'react'
import PropTypes from 'prop-types'
import CustomSnackbar from '../CustomSnackbar'

const initialState = { open: false, message: '', variant: 'error' }

function reducer(state, action) {
  switch (action.type) {
    case 'show': {
      const { message, variant } = action.payload
      return { ...state, open: true, message, variant }
    }
    case 'hide':
      return { ...state, open: false }
    default:
      return state
  }
}

export const SnackbarContext = createContext({})

export default function SnackbarContextProvider({ children }) {
  const [snackbar, dispatch] = useReducer(reducer, initialState)

  function handleClose() {
    dispatch({ type: 'hide' })
  }

  return (
    <SnackbarContext.Provider value={{ snackbar, dispatch }}>
      {children}
      <CustomSnackbar {...snackbar} handleClose={handleClose} />
    </SnackbarContext.Provider>
  )
}

SnackbarContextProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
}
