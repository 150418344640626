export const getFirstAndLastName = (stringifiedName) => {
  const trimmedName = stringifiedName.trim()
  const nameToArray = trimmedName.split(' ')
  const [firstName] = nameToArray

  const lastName = nameToArray[nameToArray.length - 1]
  return `${firstName} ${lastName}`
}

const planets = [
  {
    name: 'Aurora',
    splash: 'splash1',
    percent: 0.15,
  },
  {
    name: 'Gaia',
    splash: 'splash2',
    percent: 0.25,
  },
  {
    name: 'Dagobah',
    splash: 'splash3',
    percent: 0.25,
  },
  {
    name: 'Naboo',
    splash: 'splash4',
    percent: 0.2,
  },
  {
    name: 'Krypton',
    splash: 'splash5',
    percent: 0.15,
  },
]

export const getPlanetByQuestionNumber = (totalOfQuestions, currQuestion) => {
  const planetsWithMaxQuesions = planets.reduce((x1, x2, index) => {
    const getAccPercent = planets.reduce(
      (acc, curr, i) => (i > index ? acc : curr.percent + acc),
      0,
    )

    const lastQuestionIndex =
      index + 1 === planets.length
        ? totalOfQuestions
        : Number((totalOfQuestions * getAccPercent).toFixed())

    return [...x1, { ...x2, lastQuestionIndex, index }]
  }, [])
  return planetsWithMaxQuesions.find((planet) => currQuestion <= planet.lastQuestionIndex).index
}
