import api from './api'

const userInfo = (keycloakId) => api.get(`/examinees/keycloakId/${keycloakId}`)

const answer = (value, codAssessment, codExaminee, questionIndex) =>
  api.get(
    `testingsession/answer?value=${value}&codAssessment=${codAssessment}&codExaminee=${codExaminee}&questionIndex=${questionIndex}`,
  )

const interrupt = ({ codAssessment, codExaminee }) =>
  api.get(`testingsession/interrupt?codAssessment=${codAssessment}&codExaminee=${codExaminee}`)

const next = ({ codAssessment, codExaminee, questionIndex }) =>
  api.get(
    `testingsession/next?codAssessment=${codAssessment}&codExaminee=${codExaminee}&questionIndex=${questionIndex}`,
  )

const progress = ({ codAssessment, codExaminee }) =>
  api.get(`testingsession/progress?codAssessment=${codAssessment}&codExaminee=${codExaminee}`)

const start = ({ codAssessment, codExaminee }) =>
  api.get(`testingsession/start?codAssessment=${codAssessment}&codExaminee=${codExaminee}`)

export { answer, interrupt, next, userInfo, progress, start }
