import axios from 'axios'
import UserService from '../services/UserService'

const api = () => {
  const axiosInstance = axios.create({
    baseURL: `${process.env.REACT_APP_BASE_API_URL}/api/v1/`,
    timeout: 180000,
  })

  axiosInstance.interceptors.request.use((request) => {
    const token = UserService.getToken()

    if (token) {
      request.headers.common.Authorization = `Bearer ${token}`
    }

    return request
  })
  return axiosInstance
}

export default api()
