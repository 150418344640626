import { answer, next, interrupt, start } from '../api/calls'
import { getPlanetByQuestionNumber } from '../components/utils'

const persistState = localStorage.getItem('question')

const blankState = {
  totalOfQuestions: 0,
  questionsAnswered: 0,
  question: {},
  recover: false,
  error: {
    status: false,
    message: '',
  },
  planet: 0,
  type: 'guide',
  loading: false,
}

const initialState = persistState ? JSON.parse(persistState) : blankState

const questionReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'INCREASE_PLANET':
      return { ...state, planet: state.planet + 1 }
    case 'SET_QUESTION':
      localStorage.setItem('question', JSON.stringify({ ...state, ...action.payload }))
      return { ...state, ...action.payload }
    case 'GET_QUESTION':
      return {
        ...state,
        question: localStorage.getItem('question'),
      }
    case 'LOADING_TEST':
      return { ...state, loading: true }
    case 'UNSET_LOADING_TEST':
      return { ...state, loading: false }
    case 'SET_TEST_INTERRUPTED':
      localStorage.setItem('question', JSON.stringify(blankState))
      return blankState
    case 'SET_FAILED_QUESTION':
      return { ...state, error: action.payload, loading: false }
    case 'UNSET_FAILED_QUESTION':
      return { ...state, error: blankState.error, loading: false }
    default:
      return state
  }
}

const questionMiddleware = (dispatch) => (action) => {
  const { payload, type } = action
  const question = JSON.parse(localStorage.getItem('question'))
  const actions = {
    START_TEST: async () => {
      try {
        const { data: startData } = await start({
          codAssessment: localStorage.getItem('codAssessment'),
          codExaminee: action.payload.codExaminee,
        })
        const { data: newQuestion } = await next({
          codAssessment: localStorage.getItem('codAssessment'),
          codExaminee: localStorage.getItem('codExaminee'),
          questionIndex: 0,
        })

        dispatch({
          type: 'SET_QUESTION',
          payload: {
            totalOfQuestions: startData.qtdQuestions,
            questionsAnswered: newQuestion.currentIndex,
            recover: newQuestion.currentIndex > 0,
            question: newQuestion,
            planet: getPlanetByQuestionNumber(startData.qtdQuestions, newQuestion.currentIndex),
            loading: false,
            type:
              newQuestion.currentIndex === 0 || newQuestion.forwardTo === 'assessment/intro'
                ? 'guide'
                : 'question',
          },
        })
      } catch (error) {
        dispatch({
          type: 'SET_FAILED_QUESTION',
          payload: { status: true, message: error.response && error.response.data.message },
        })
      }
    },
    ANSWER_QUESTION: async () => {
      try {
        dispatch({ type: 'LOADING_TEST' })
        await answer(
          payload,
          localStorage.getItem('codAssessment'),
          localStorage.getItem('codExaminee'),
          question.question.currentIndex,
        )
        const { data: newQuestion } = await next({
          codAssessment: localStorage.getItem('codAssessment'),
          codExaminee: localStorage.getItem('codExaminee'),
          questionIndex: question.question.currentIndex + 1,
        })

        dispatch({
          type: 'SET_QUESTION',
          payload: {
            question: newQuestion,
            loading: false,
            questionsAnswered: newQuestion.currentIndex,
            type:
              newQuestion.currentIndex === 0 || newQuestion.forwardTo === 'assessment/intro'
                ? 'guide'
                : 'question',
          },
        })
      } catch (error) {
        dispatch({
          type: 'SET_FAILED_QUESTION',
          payload: { status: true, message: error.response && error.response.data.message },
        })
      }
    },
    INTERRUPT_TEST: async () => {
      try {
        dispatch({ type: 'LOADING_TEST' })
        await interrupt({
          codAssessment: localStorage.getItem('codAssessment'),
          codExaminee: localStorage.getItem('codExaminee'),
        })
        dispatch({ type: 'SET_TEST_INTERRUPTED' })
      } catch (error) {
        dispatch({ type: 'SET_TEST_INTERRUPTED' })
      }
    },
    RESET_TEST: async () => {
      try {
        await interrupt({
          codAssessment: localStorage.getItem('codAssessment'),
          codExaminee: localStorage.getItem('codExaminee'),
        })
        await actions.START_TEST()
      } catch (error) {
        dispatch({ type: 'SET_FAILED_QUESTION' })
      }
    },
    GET_NEXT_QUESTION: async () => {
      try {
        const { data: newQuestion } = await next({
          codAssessment: localStorage.getItem('codAssessment'),
          codExaminee: localStorage.getItem('codExaminee'),
          questionIndex: question.question.currentIndex + 1,
        })
        dispatch({
          type: 'SET_QUESTION',
          payload: {
            questionsAnswered: newQuestion.currentIndex === 0 ? 0 : newQuestion.currentIndex,
            question: newQuestion,
            type:
              newQuestion.currentIndex === 0 || newQuestion.forwardTo === 'assessment/intro'
                ? 'guide'
                : 'question',
          },
        })
      } catch (error) {
        dispatch({
          type: 'SET_FAILED_QUESTION',
          payload: { status: true, message: error.response && error.response.data.message },
        })
      }
    },
    RESET_QUESTION: async () => {
      dispatch({ type: 'UNSET_FAILED_QUESTION' })
      dispatch({ type: 'LOADING_TEST' })
      await start({
        codAssessment: localStorage.getItem('codAssessment'),
        codExaminee: localStorage.getItem('codExaminee'),
      })
      dispatch({ type: 'UNSET_LOADING_TEST' })
    },
  }

  if (actions[type]) return actions[type]()
  return dispatch(action)
}

const questionActions = (dispatch) => ({
  answerQuestion: (data) => {
    dispatch({ type: 'ANSWER_QUESTION', payload: data })
  },
  startTest: (data) => {
    dispatch({ type: 'START_TEST', payload: data })
  },
  getQuestion: (data) => {
    dispatch({ type: 'GET_NEXT_QUESTION', payload: data })
  },
  resetTest: (data) => {
    dispatch({ type: 'RESET_TEST', payload: data })
  },
  interruptTest: (data) => {
    dispatch({ type: 'INTERRUPT_TEST', payload: data })
  },
  cleanTest: () => {
    dispatch({ type: 'SET_TEST_INTERRUPTED' })
  },
  increasePlanet: () => {
    dispatch({ type: 'INCREASE_PLANET' })
  },
  removeRecover: () => {
    dispatch({
      type: 'SET_QUESTION',
      payload: { recover: false },
    })
  },
  removeError: (data) => {
    dispatch({ type: 'RESET_QUESTION', payload: data })
  },
})

export { questionMiddleware, questionReducer, questionActions, initialState }
