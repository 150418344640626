import React, { useCallback } from 'react'
import { Route } from 'react-router-dom'
import PropTypes from 'prop-types'
import UserService from '../services/UserService'

const RouteWrapper = ({ component, isPrivate, ...rest }) => {
  const login = useCallback(async () => {
    await UserService.doLogin()
  }, [])

  if (!UserService.isLoggedIn() && isPrivate) {
    login()
  }

  return <Route {...rest} component={component} />
}

RouteWrapper.propTypes = {
  component: PropTypes.any.isRequired,
  isPrivate: PropTypes.bool,
}

RouteWrapper.defaultProps = {
  isPrivate: false,
}

export default RouteWrapper
